import AboutMe from './components/aboutMe';
import ContactMe from './components/contactMe';
import SkillDivid from './components/skillDivid';
import SkillMe from './components/skillMe';
import ProfDivid from './components/proDivid';
import EduDivid from './components/eduDivid';
import ExpMe from './components/expMe';
import EduMe from './components/eduMe';
import './App.css';


function App() {
  return (
    <div className="App">
      <aside className='sidebar'>
        <AboutMe/>
        <ContactMe/>
        <SkillDivid/>
        <SkillMe/>
      </aside>
    <main className="main-content">
      <ProfDivid/>
      <ExpMe/>
      <EduDivid/>
      <EduMe/> 
    </main>
    </div>
  );
}

export default App;

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import '../css/eduDivid.css'


function EduDivid() {
    return (
        <div className="edu_divi">  
            <h2><FontAwesomeIcon icon={faGraduationCap} className="faIcon" />Educational Experience</h2>
        </div>
    );
}

export default EduDivid;
import React from "react";
import "../css/skills.css";

function Skills({ skills }) {
    return (
        <div className="skills">
            <ul className="skill_box">
                {skills.map(skill => (
                    <li key={skill} className="skill">{skill}</li>
                ))}
            </ul>        
        </div>
    );
}   

export default Skills;
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot} from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import '../css/contact.css'

function Contact(props) { 
    return (
        <aside className="contact_info">
            <div className="location">
                <FontAwesomeIcon icon={faLocationDot} size="xl" />
                <p>{props.location}</p>
            </div>
            <div className="info_contacts">
                <a href={props.pdf} download={props.dis_pdf} style={{ textDecoration: 'none', color: 'inherit' }}>   
                    <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#b70101" }} />
                </a>
                <a href={props.Linkedin} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>   
                    <FontAwesomeIcon icon={faLinkedin} size="xl" style={{ color: "#0077B5" }} />
                </a>
                <a href={`mailto:${props.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>   
                    <FontAwesomeIcon icon={faEnvelope} size="xl" />
                </a>
            </div>
        </aside>
    );
}

export default Contact;
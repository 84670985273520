import React from "react";
import About from './about';

function AboutMe(){
    return (
        <div>
            <About
                photo="dt01.jpg"
                name="David Townsend"
                job_title="QA Engineer"
                about="My professional journey combines technical expertise and a vibrant
                love for interactive media. Known for a naturally inquisitive mindset, I consistently delve into the why behind
                technologies and processes."
            />
        </div>
    );
}

export default AboutMe;
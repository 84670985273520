import React from "react";
import Skills from "./skills";

const SkillMe = () => {
    const skills = [
        'Python', 'JavaScript', 'HTML/CSS', 'SQL', 'React', 'Linux', 
        'OSX', 'Windows', 'Photoshop', 'After Effects', 'Maya', 'UE', 'Unity'
    ];

    return (
        <div>
            <Skills skills={skills} />
        </div>
    );
}

export default SkillMe;
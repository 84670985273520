import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import '../css/proDivid.css'


function ProfDivid() {
    return (
        <div className="prof_divi">  
            <h2><FontAwesomeIcon icon={faBriefcase} className="faIcon_pro" />Professional Experience</h2>
        </div>
    );
}

export default ProfDivid;
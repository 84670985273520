import React from "react";
import '../css/about.css'


function About({ photo, name, about, job_title }) {
    return (
        <aside className="about">
            <div className="about_photo">
                <img src={require(`/public/assets/${photo}`)} alt="Resume_Photo" />  {/* put assets folder in public folder (I don't know if this is correctly) */}
            </div>
                <h1 className="about_name">{name}</h1>
                <hr />
                <p className="about_job">{job_title}</p>
            <div>
                <p className="about_me">{about}</p>
            </div>
                <hr />
        </aside>
    );
}

export default About;   
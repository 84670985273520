import React from "react";
import ExpCard from "./expCard";

function ExpMe(){
    const experiences = [
        {
            id: 1,
            logo: "../assets/Google.jpg",
            companyA: "Google/Fitbit ",
            companyB: "(Contractor with Raindrops)",
            title: "QA Engineer",
            location: "San Francisco, CA",
            period: "July 2022 - Present",
            duties: [
            "Validating a suite of bio-sensors on wearable electronics throughout their development life-cycle, namely Fitbit and Pixel watches. Sensors include thermal, IMU, electrode based (cEDA & ECG), and LED/PD (PPG) sensors within their form factor",
            "Executed Python test scripts to test hardware functions and feature",
            "Fostered strong relationships with teams, leveraging a keen eye for detail and a questioning approach to optimize hardware features",
            "Documenting QA processes, ensuring comprehensive data collection"
            ]
          },
          {
            id: 2,
            logo: "../assets/Aechelon.png",
            companyA: "Aechelon technology",
            title: "Runtime/Tools QA Tester",
            location: "San Francisco, CA", 
            period: "July 2021 - April 2022",
            duties: [
            "Tested tools used by the company's clients to publish unique elements into Aechelons visual database",
            "Triaged nightly builds",
            "Created documentation for the Tools department"
            ]
          },
          {
            id: 3,
            logo: "../assets/magic leap.png",
            companyA: "Magic Leap",
            title: "QA Engineer (Contractor)",
            location: "Sunnyvale, CA",
            period: "May 2017 - July 2021",
            duties: [
            "Specialized in testing SLAM-related features, often drawing parallels with gaming experiences to improve testing strategies",
            "Tested SLAM features for Magic Leap 1 and early Magic Leap 2 hardware releases,prioritizing critical bugs and developing edge case test scenarios",
            "Ensured SLAM features, specifically tracking and Localization, maintained low jitter inuser experience during daily test builds",
            "Coordinated with various QA teams, leveraging my observant nature to enhance interteam collaboration and product excellence"
            ]
          },
          {
            id: 4,
            logo: "../assets/Oculus.png",
            companyA: "Oculus",
            companyB: "(a Meta company)",
            title: "QA Vendor",
            location: "Menlo Park, CA",
            period: "Feb 2015 - Nov 2016",
            duties: [
            "Developed test plans for Unity and UE4 Oculus Integration",
            "Execute created test plans",
            "Worked with Oculus Unity and UE4 internal developers to resolve issues",
            "Helped Launch the Oculus Rift and Gear VR"
            ]
          }, 
          {
            id: 5,
            logo: "../assets/Viggle.jpeg",
            companyA: "Viggle",
            title: "QA Engineer",
            location: "San Francisco, CA",
            period: "Feb 2012 - July 2013",
            duties: [
            "Wrote test plans for Viggle and MyGuy app",
            "Performed blackbox testing on Viggle and MyGuy app",
            "Interacted with developers on a daily basis to resolve issues",
            ]
          },
          {
            id: 6,
            logo: "../assets/Loyalize.png",
            companyA: "Loyalize.com",
            title: "QA Tester",
            location: "San Francisco, CA",
            period: "April 2011 - Jan 2012",
            duties: [
            "Wrote and executed test plans on web portals and mobile apps",
            ]
          },
          {
            id: 7,
            logo: "../assets/The Orphanage.png",
            companyA: "The Orphanage",
            companyB: "(defunct VFX Company)",
            title: "Resource Administrator",
            location: "San Francisco, CA",
            period: "Dec 2007 - Feb 2009",
            duties: [
            "Performed comprehensive quality checks on dailies",
            "Point of contact for trouble shooting Plug-in issues with After Effects, Premiere and Photoshop",
            "Movie credits on Iron Man, Red Cliff and Don't Mess with the Zohan",
            ]
          },
                    
        ];

return (
    
    experiences.map(exp => (
        <ExpCard key={exp.id} {...exp} />
    ))
);

}

export default ExpMe;
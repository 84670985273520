import React from "react";
import Contact from './contact';   


function ContactMe() {
    return (
        <div>
            <Contact
                location="San Francisco, CA"
                pdf="/assets/TownsendDavidResume_2O24.pdf"
                dis_pdf="David Townsend Resume"
                Linkedin="https://www.linkedin.com/in/david-townsend-0999242/"
                email="dtownsend90@gmail.com"
            />
        </div>
    );  
}

export default ContactMe;


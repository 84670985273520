import React from "react";
import "../css/expCard.css";


function ExpCard ({ logo, companyA, companyB, title, period, duties, location }){

    return(
        <div className="card">
            <div className="exp_img">
                <img src={logo} alt="Company Logo" />
            </div>
            <div className="detailsA">
                <div className="card_header">
                <div className="card_header_sub">
                    <h3 className="company_schoolA">{companyA}</h3>
                    <h5 className="company_schoolB">{companyB}</h5>
                    <p className="job_title_card">{title}</p>
                </div>
            <div className="time">
                <p>{location}</p>
                <time>{period}</time>
            </div>
            </div>
                <div className="detailsB">
                    <ul>
                    {duties.map((duty, index) => (
                        <li key={index} className="dets">{duty}</li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ExpCard; 

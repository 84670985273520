import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGears } from '@fortawesome/free-solid-svg-icons';
import '../css/skillDivid.css'


function SkillDivid() {
    return (
        <div className="skilld_divi">  
            <h2><FontAwesomeIcon icon={faGears} className="faIcon" />Skills</h2>
        </div>
    );
}

export default SkillDivid;
import React from "react";
import "../css/eduCard.css";


function EduCard ({ logo, school, degree, period, duties, location }){

    return(
        <div className="card">
            <div className="edu_img">
                <img src={logo} alt="University Logo" />
            </div>
            <div className="detailsA">
                <div className="card_header">
                <div className="card_header_sub">
                    <h3 className="company_school">{school}</h3>
                    <p className="degree_title_card">{degree}</p>
                </div>
            <div className="time">
                <p>{location}</p>
                <time>{period}</time>
            </div>
            </div>
                <div className="detailsB">
                    <ul>
                    {duties.map((duty, index) => (
                        <li key={index} className="dets">{duty}</li>
                    ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default EduCard; 

import React from "react";
import EduCard from "./eduCard";

function EduMe(){
    const educations = [
        {
            id: 1,
            logo: "../assets/Academy-of-Art-University_Logo.jpg",
            school: "Academy of Art University San Francisco",
            degree: "Master of Fine Arts (unfinshed) - Computer Animation",
            location: "San Francisco, CA",
            duties: [
            "Creation of thesis animation called Stuck",
            ]
          },
          {
            id: 2,
            logo: "../assets/Syracuse University.png",
            school: "Syracuse University",
            degree: "Bachelor of Fine Arts - Computer Graphics",
            location: "Syracuse, NY", 
            duties: [

            ]
          }
    ];
    return (
        educations.map(edu => (
            <EduCard key={edu.id} {...edu} />
        ))
    );
}

export default EduMe;